import dayjs from 'dayjs'

export function formatCurrency (value: number): string {
  const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: 'EUR'
  });
  return formatter.format(Number(value));
}

export function formatNumber(value: number): number {
  const round = Math.round(value * 100) / 100;
  return round;
}

export function formatDate(date: Date) {
  return dayjs(date).format("DD/MM/YYYY")
}

export function formatPercent(value: number) {
  const formatter = new Intl.NumberFormat('fr-FR', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
   });
  return formatter.format(Number(value)/100);
}

export function formatHTMLToString(value: string) {
  return value ? value.replace(/<[^>]+>/g, '') : null;
}